<template>
  <div class="proOrder">
    <div class="content">
      <div class="warehous" v-loading="loading">
        <div class="census">
          <div class="item">
            <div class="title">订单合计</div>
            <div class="num">{{ census.total }}</div>
          </div>
          <div class="item">
            <div class="title">产品数量</div>
            <div class="num">{{ census.totalNum }}</div>
          </div>
          <div class="item">
            <div class="title">产品总金额</div>
            <div class="num">{{ $filters.toYuan(census.totalPrice, '元') }} <span class="unit">元</span></div>
          </div>
        </div>
        <div class="queryBoxOne">
          <div class="btnBox">
            <el-select placeholder="请选择仓库" popper-class="no-border-select-popper" class="no-boder-select bdr"
                       v-model="formData.stockId" @change="getList">
              <el-option v-for="item in sotckList" :key="item.stockId" :label="item.stockName" :value="item.stockId"/>
            </el-select>
          </div>
          <div class="queryItem">
            <el-date-picker
                v-model="formData.time"
                type="daterange"
                class="w280"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change='getList'
            />
            <type-cascader v-model="formData.orderType" type="in" @change="getList"  class="w160"></type-cascader>
            <el-input
                v-elInput
                v-model="formData.query"
                class="w160"
                placeholder="产品名称/简称/条码"
                @change="getList"
                clearable
            />
            <SelectByAbcModal
                typeName="supplier"
                :maxNum="1"
                v-model:list="formData.supplier"
                className="w160"
                @change="getList"
                placeholder='供应商名称'
            ></SelectByAbcModal>
            <SelectByAbcModal
                typeName="punter"
                :maxNum="1"
                v-model:list="formData.searchList"
                className="w160"
                @change="getList"
                placeholder='客户名称'
            ></SelectByAbcModal>
            <el-input
                v-elInput
                v-model="formData.orderSn"
                class="w160"
                placeholder="订单编号"
                @change="getList"
                clearable
            />
            <div class="queryBtn">
              <el-button type="primary" @click="getList"
              >
                <el-icon>
                  <Search/>
                </el-icon
                >
              </el-button>
              <el-button @click="fieldCheckShow">展示项设置</el-button>
            </div>
          </div>
        </div>
        <query-form>
          <zh-table :data="tableData"
                    @row-click="checkGoods"
                    @sort-change='changeSort'>
            <el-table-column
                type="index"
                width="100"
                label="序号"
                align="center"
                fixed
            />
            <template v-for="item in titleList" :key="item.fieldKey">
              <template v-if="item.isShow">
                <el-table-column width="300" label="产品名称" align="left" v-if="item.fieldKey ==='productName'">
                  <template #default='scope'>
                    <div class="nameBox">
                <span
                    class="name"
                    v-for="(item,index) in scope.row.productName"
                    :key="item"
                >
                  <template v-if='index<3'> {{ item }}</template>
                 </span
                 >
                      <span class="more" v-if="scope.row.productName.length > 3"
                      >+{{ scope.row.productName.length - 3 }}</span
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    v-else-if="item.fieldKey !== 'orderTypeDesc'"
                    align="center"
                    :prop="item.fieldKey"
                    :label="item.title"
                    :width="item.width"
                    :sortable='item.sortable'
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    v-else
                    align="center"
                    :prop="item.fieldKey"
                    :label="item.title"
                    :width="item.width"
                >
                  <template #default="scope">
                    <div class="dp_f" style="justify-content: center">
                      <div
                          :class="[
                      'zh-label-text',
                      `${
                       isInOrder(scope.row.orderType) ? 'in-stock' : 'out-stock'
                      }`,
                      `order-type-${scope.row.orderType}`,
                    ]"
                      >
                        {{ scope.row.orderTypeDesc }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </template>
            </template>
          </zh-table>

        </query-form>
        <div class="mainPage">
          <el-pagination
              v-model:current-page="pageInfo.pageNumber"
              layout="total, prev, pager, next , jumper"
              :total="pageInfo.total"
              v-model:page-size="pageInfo.pageSize"
              @current-change="handleCurrentChange"
          />
        </div>
        <FieldsCheck
            :list="titleList"
            v-model="customFields"
            @close="checkFileds"
        />
        <!-- 采购订单详情 -->
        <OrderInfo @close="closeOrder" ref="orderShow"></OrderInfo>
      </div>
    </div>
  </div>
</template>
<script setup>
import OrderInfo from "@/views/order/components/orderDetailHistory.vue";
import {stockList} from '@/utils/api/procurement/stock.js'
import {getCurrentInstance, onMounted, reactive, ref, toRefs} from "vue";
import {useStore} from "vuex";
import {columns} from "./config.js";
import {productList} from "@/utils/api/product/product.js";
import {orderList} from '@/utils/api/order.js';
import QueryForm from "@components/element/queryForm.vue";
import ZhTable from "@components/element/table.vue";
import {usePagination} from "@utils/tool/page";
import {isInOrder} from "@utils/config";
import TypeCascader from "@components/form/TypeCascader/index.vue";

const {proxy} = getCurrentInstance();
const store = useStore();
const loading = ref(false);
//控组详情弹窗是否展示
const orderShow = ref(null);
const allocateShow = ref(null)
//仓库列表
const sotckList = ref([])
const closeOrder = () => {
};
const closeAllocate = () => {

}
/*
  关于统计
*/
const census = reactive({
  total: 0,
  totalNum: 0,
  totalPrice: 0,
});
const formData = reactive({
  query: "",
  supplier: [],
  time: [],
  stockId: '',
  createTimeBegin: "",
  createTimeEnd: "",
  orderType: [],
  searchList: [],
  orderSn: '',
});
const {pageInfo} = usePagination()

//获取仓库列表
const getStockListData = async () => {
  const res = await stockList()
  if (res.code === 0) {
    if (res.data?.list?.length === 1) {
      formData.stockId = res.data.list[0].stockId
      sotckList.value = res.data.list
    } else if (res.data?.list?.length && res.data?.list?.length > 1) {
      sotckList.value.push({stockId: '', stockName: '全部仓库'})
      sotckList.value.push(...res.data.list)
    }
    getList()
  } else {
    proxy.$message.error(res.msg)
  }
}

const titleList = ref(cloneDeep(columns));
const titleListForCheck = ref(cloneDeep(columns));
const tableData = ref([]);
const customFields = ref(false);
const fieldCheckShow = () => {
  customFields.value = true;
};
const checkFileds = (list) => {
  customFields.value = false
};
const getList = () => {
  if (formData.supplier[0] && formData.searchList[0]) {
    tableData.value = []
    return
  }
  let beginTime = 0
  let endTime = 0
  if (formData.time && formData.time[0]) {
    beginTime = moment(formData.time[0]).format('YYYY-MM-DD') + ' 00:00:00'
  }
  if (formData.time && formData.time[1]) {
    endTime = moment(formData.time[1]).format('YYYY-MM-DD') + ' 23:59:59'
  }
  const orderType = formData.orderType?.[0] || 100
  let typeId = formData.orderType?.[1] ?? ''
  let obj = {
    orderType,
    typeId,
    beginTime: moment(beginTime).valueOf(),
    endTime: moment(endTime).valueOf(),
    thirdId: formData.supplier[0]?.supplierId || formData.searchList[0]?.punterId || '',
    query: formData.query,
    pageSize: pageInfo.pageSize,
    pageNumber: pageInfo.pageNumber,
    sortField: formData.sortField,
    sortType: formData.sortType,
    stockId: formData.stockId,
    orderStatus: 1,
    orderSn: formData.orderSn
  }
  orderList(obj)
      .then((res) => {
        if (res.code !== 0) {
          proxy.$message.error(res.msg);
          return;
        }
        tableData.value = res.data?.list || [];
        tableData.value.forEach((item) => {
          item.price = proxy.$filters.toYuan(item.price, '元')
          item.totalPrice = proxy.$filters.toYuan(item.totalPrice, '元')
          item.realityTotalPrice = proxy.$filters.toYuan(item.realityTotalPrice, '元')
          item.time = proxy.$filters.timeFormat(item.time, 1)
          item.productName = item.productName ? item.productName.split(',') : []
          if(!item.thirdName){
            item.thirdName = '--'
          }
        })
        pageInfo.total = res.data?.total || 0;
        census.total = res.data?.census?.total || 0;
        census.totalNum = res.data?.census?.totalNum || 0;
        census.totalPrice = res.data?.census?.totalPrice || 0;
        proxy.$emit('change')
      })
};
const changeSort = ({column, prop, order}) => {
  if (!order) {
    formData.sortField = ''
    formData.sortType = ''
  } else {
    formData.sortField = prop
    formData.sortType = order === 'descending' ? 'desc' : 'asc'
  }
  getList()
}
const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};
//点击采购订单详情
const checkGoods = (e) => {
  store.commit('PURCHASE_ORDER_INFO', e)
  orderShow.value.openDetails()
}
onMounted(() => {
  getStockListData()
})
</script>

<script>
import {Search, Plus, ArrowDown} from "@element-plus/icons-vue";
import {reactive, toRefs, ref} from "vue";
import {productList} from "@/utils/api/product/product.js";
import {cloneDeep} from "lodash";
import FieldsCheck from "@/components/fieldsCheck/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
import moment from 'moment';

export default {
  name: "warehous",
  components: {
    Search,
    Plus,
    FieldsCheck,
    ArrowDown,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.proOrder {
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  position: relative;

  .el-tabs__header {
    margin-bottom: 0;
  }

  > .content {
    border-radius: 16px;
    background: #fff;
    padding: 12px 32px 0;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }
}

.warehous {
  padding-top: 8px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 70px;
  //.tabList {
  //  margin-top: 20px;
  //  height: calc(100% - 160px);
  //}
  .imgList {
    img {
      width: 56px;
      height: 42px;
      border-radius: 8px;
      overflow: hidden;
    }

    .nameBox {
      .name {
        margin-right: 18px;
        font-size: 14px;
        display: inline-block;
        @include textOverflow(5em)
      }

      .more {
        color: #73737f;
      }
    }

    .statusBtn {
      display: flex;
      justify-content: center;
    }

    .status {
      font-size: 12px;
      color: #3759ff;
      width: 56px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: rgba(55, 89, 255, 0.1);
      border-radius: 4px;

      &.status2 {
        color: #ff7b5d;
        background-color: rgba(255, 123, 93, 0.1);
      }
    }
  }

  .queryBoxOne {
    margin-top: 16px;
  }
}

:deep(.el-range-separator) {
  font-weight: 400;
  color: $fsColor2;
}
</style>
