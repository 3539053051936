//设置表头配置
export const columns = [
    {
      title:"订单编号",
      fieldKey:"orderSn",
      width:200,
      isShow:true
    },
    {
        title: "客户/供应商名称",
        fieldKey: "thirdName",
        width: '300',
        isShow: true
    },
    {
        title: "产品名称",
        fieldKey: "productName",
        width: 200,
        isShow: true
    },
    {
      title:"产品数量",
        fieldKey: "realityCount",
      width:200,
      isShow:true,
      sortable:true
    },
    {
      title:"产品金额(元)",
        fieldKey: "realityTotalPrice",
      width:200,
      isShow:true,
      sortable:true
    },
    {
      title:"入库类型",
      fieldKey:"orderTypeDesc",
      width:100,
      isShow:true
    },
    {
      title:"入库仓库",
      fieldKey:"stockName",
      width:100,
      isShow:true
    },
    {
      title:"操作人",
      fieldKey:"userName",
      width:100,
      isShow:true
    },
    {
      title:"入库时间",
      fieldKey:"time",
      width:200,
      isShow:true,
      sortable:true
    },
    {
      title:"备注",
      fieldKey:"remark",
      width:'auto',
      isShow:true
    },
]
